import axios from "axios";
import { ChangeEvent, useState } from "react";
import "./App.css";

function App() {
  const [auth, setAuth] = useState(false);
  const [phoneNumbersFile, setPhoneNumbersFile] = useState<File>();
  const [message, setMessage] = useState<string>();

  const handleAuth = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === process.env.REACT_APP_PASSWORD) {
      setAuth(true);
    }
  };

  const submit = async () => {
    if (!phoneNumbersFile || !message)
      return alert("Please upload file and fill in message");

    const formData = new FormData();
    formData.append("phoneNumbersFile", phoneNumbersFile);
    formData.append("message", message);
    try {
      await axios.post("https://sms.dadi.nz/batch", formData);
      alert("SMS Submitted to backend!");
      window.location.reload();
    } catch (e) {
      alert(e);
    }
  };

  if (!auth)
    return (
      <div className="App">
        <h1>Swing Dadi - Marketing SMS</h1>{" "}
        <input type="password" onChange={(e) => handleAuth(e)} />
      </div>
    );

  return (
    <div className="App">
      <h1>Swing Dadi - Marketing SMS</h1>
      <div>
        <div>Upload File (CSV)</div>
        <input
          style={{ marginTop: 15 }}
          type="file"
          onChange={(e) =>
            e.target.files && setPhoneNumbersFile(e.target.files[0])
          }
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <div>Input Message</div>
        <textarea
          value={message}
          style={{ marginTop: 10 }}
          maxLength={80}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div>
        <button onClick={submit}>Submit</button>
      </div>
    </div>
  );
}

export default App;
